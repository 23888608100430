var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrlcysz drag",attrs:{"id":"jrlcysz"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_常用设置")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("加热炉常用设置")]),_c('div',{staticClass:"lable1"},[_c('div',{staticClass:"fl"},[_vm._v("单根钢坯重量（T)")]),_c('div',{staticClass:"fl lcolor",on:{"click":function($event){return _vm.toIpt(_vm.infoList.G_KHTJ.GPDZ, '单根钢坯重量', 'GPDZ', 'G_KHTJ')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_KHTJ.GPDZ)+" ")]),_c('div',{staticClass:"fr button-top",on:{"click":function($event){return _vm.toCompon(4)}}},[_vm._v("软测量")]),_c('div',{staticClass:"fr button-top1",on:{"click":function($event){return _vm.toCompon(5)}}},[_vm._v("数据库保存")])]),_c('div',{staticClass:"main-top"},[_vm._m(0),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("炉顶温度设定保护上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T11SP_H,
            '均热段炉顶温度设定保护上限',
            'T11SP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T11SP_H)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T21SP_H,
            '三加热段炉顶温度设定保护上限',
            'T21SP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T21SP_H)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T31SP_H,
            '二加热段炉顶温度设定保护上限',
            'T31SP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T31SP_H)+" ")]),_c('div',{staticClass:"row1-col3 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T41SP_H,
            '一加热段炉顶温度设定保护上限',
            'T41SP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T41SP_H)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("炉顶温度设定保护下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T11SP_L,
            '均热段炉顶温度设定保护下限',
            'T11SP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T11SP_L)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T21SP_L,
            '三加热段炉顶温度设定保护下限',
            'T21SP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T21SP_L)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T31SP_L,
            '二加热段炉顶温度设定保护下限',
            'T31SP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T31SP_L)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.T41SP_L,
            '一加热段炉顶温度设定保护下限',
            'T41SP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.T41SP_L)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("空烟温度设定保护上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.JRD_KPTH,
            '均热段空烟温度设定保护上限',
            'JRD_KPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRD_KPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.SJR_KPTH,
            '三加热段空烟温度设定保护上限',
            'SJR_KPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJR_KPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.EJR_KPTH,
            '二加热段空烟温度设定保护上限',
            'EJR_KPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJR_KPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.YJR_KPTH,
            '一加热段空烟温度设定保护上限',
            'YJR_KPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJR_KPTH)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("空烟温度设定保护下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.JRD_KPTL,
            '均热段空烟温度设定保护下限',
            'JRD_KPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRD_KPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.SJR_KPTL,
            '三加热段空烟温度设定保护下限',
            'SJR_KPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJR_KPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.EJR_KPTL,
            '二加热段空烟温度设定保护下限',
            'EJR_KPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJR_KPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.YJR_KPTL,
            '一加热段空烟温度设定保护下限',
            'YJR_KPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJR_KPTL)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("煤烟温度设定保护上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.JRD_MPTH,
            '均热段煤烟温度设定保护上限',
            'JRD_MPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRD_MPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.SJR_MPTH,
            '三加热段煤烟温度设定保护上限',
            'SJR_MPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJR_MPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.EJR_MPTH,
            '二加热段煤烟温度设定保护上限',
            'EJR_MPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJR_MPTH)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.YJR_MPTH,
            '一加热段煤烟温度设定保护上限',
            'YJR_MPTH',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJR_MPTH)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("煤烟温度设定保护下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.JRD_MPTL,
            '均热段煤烟温度设定保护下限',
            'JRD_MPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRD_MPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.SJR_MPTL,
            '三加热段煤烟温度设定保护下限',
            'SJR_MPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJR_MPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.EJR_MPTL,
            '二加热段煤烟温度设定保护下限',
            'EJR_MPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJR_MPTL)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP.YJR_MPTL,
            '一加热段煤烟温度设定保护下限',
            'YJR_MPTL',
            'G_TEMP'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJR_MPTL)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("炉膛压力设定保护")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PFYSP_H,
            '炉膛压力控制点上限',
            'PFYSP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PFYSP_H)+" ")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PFYSP_L,
            '炉膛压力控制点下限',
            'PFYSP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PFYSP_L)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("空气总管压力设定保护")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PKQZGSP_H,
            '空气总管压力控制点上限',
            'PKQZGSP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PKQZGSP_H)+" ")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PKQZGSP_L,
            '空气总管压力控制点下限',
            'PKQZGSP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PKQZGSP_L)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("煤气总管压力设定保护")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点上限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PMQZGSP_H,
            '煤气总管压力控制点上限',
            'PMQZGSP_H',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PMQZGSP_H)+" ")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("控制点下限")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PVCL.PMQZGSP_L,
            '煤气总管压力控制点下限',
            'PMQZGSP_L',
            'G_PVCL'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PVCL.PMQZGSP_L)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("基本空燃比")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.JBFMB_B11,
            '均热段空燃比',
            'JBFMB_B11',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.JBFMB_B11)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.JBFMB_B21,
            '三加热段空燃比',
            'JBFMB_B21',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.JBFMB_B21)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.JBFMB_B31,
            '二加热段空燃比',
            'JBFMB_B31',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.JBFMB_B31)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.JBFMB_B41,
            '一加热段空燃比',
            'JBFMB_B41',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.JBFMB_B41)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("停止加煤气空燃比")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.SJFMB1_B11,
            '均热段停止加煤空燃比',
            'SJFMB1_B11',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.SJFMB1_B11)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.SJFMB1_B21,
            '一加热段停止加煤空燃比',
            'SJFMB1_B21',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.SJFMB1_B21)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.SJFMB1_B31,
            '一加热段停止加煤空燃比',
            'SJFMB1_B31',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.SJFMB1_B31)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_BCS.SJFMB1_B41,
            '一加热段停止加煤空燃比',
            'SJFMB1_B41',
            'G_BCS'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.SJFMB1_B41)+" ")])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("停止加煤气负压")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_PFJ.MENLUFW1,
            '停止加煤气负压',
            'MENLUFW1',
            'G_PFJ'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.MENLUFW1)+" ")]),_c('div',{staticClass:"row1-col2 fl"},[_vm._v("钢坯总数量")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP__p__RLGWXT.GPNUM,
            '钢坯总数量',
            'GPNUM',
            'G_TEMP__p__RLGWXT'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP__p__RLGWXT.GPNUM)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl"},[_c('div',{staticClass:"otherbtn",staticStyle:{"color":"#fff"},style:({ background: _vm.infoList.G_BCS.CSFMB ? 'red' : '#29DFAE' }),on:{"click":function($event){return _vm.toIpt(_vm.infoList.G_BCS.CSFMB, '初始空燃比', 'CSFMB', 'G_BCS')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_BCS.CSFMB ? "跟踪DCS" : "固定当前")+" ")])])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"row1-col1 fl"},[_vm._v("各段钢坯数量")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP__p__RLGWXT.GPNUM4,
            '均热段钢坯数量',
            'GPNUM4',
            'G_TEMP__p__RLGWXT'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP__p__RLGWXT.GPNUM4)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP__p__RLGWXT.GPNUM3,
            '三加热钢坯数量',
            'GPNUM3',
            'G_TEMP__p__RLGWXT'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP__p__RLGWXT.GPNUM3)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP__p__RLGWXT.GPNUM2,
            '二加热钢坯数量',
            'GPNUM2',
            'G_TEMP__p__RLGWXT'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP__p__RLGWXT.GPNUM2)+" ")]),_c('div',{staticClass:"row1-col2 lcolor fl",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList.G_TEMP__p__RLGWXT.GPNUM1,
            '一加热钢坯数量',
            'GPNUM1',
            'G_TEMP__p__RLGWXT'
          )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP__p__RLGWXT.GPNUM1)+" ")])])]),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.JRLParam04)?_c('JRLParam04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParamwt)?_c('JRLParamwt',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row1"},[_c('div',{staticClass:"row1-col11 fl"},[_vm._v("类别")]),_c('div',{staticClass:"row1-col21 fl"},[_vm._v("均热段")]),_c('div',{staticClass:"row1-col21 fl"},[_vm._v("三加热段")]),_c('div',{staticClass:"row1-col21 fl"},[_vm._v("二加热段")]),_c('div',{staticClass:"row1-col31 fl"},[_vm._v("一加热段")])])}]

export { render, staticRenderFns }