<template>
  <div class="jrlcysz drag" id="jrlcysz" v-draw>
    <div>
      <div class="title-hang" fl>{{ projectData.project }}_常用设置</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">加热炉常用设置</div>
    <div class="lable1">
      <div class="fl">单根钢坯重量（T)</div>
      <div
        class="fl lcolor"
        @click="toIpt(infoList.G_KHTJ.GPDZ, '单根钢坯重量', 'GPDZ', 'G_KHTJ')"
      >
        {{ infoList.G_KHTJ.GPDZ }}
      </div>
      <div class="fr button-top" @click="toCompon(4)">软测量</div>
      <div class="fr button-top1" @click="toCompon(5)">数据库保存</div>
    </div>
    <div class="main-top">
      <div class="row1">
        <div class="row1-col11 fl">类别</div>
        <div class="row1-col21 fl">均热段</div>
        <div class="row1-col21 fl">三加热段</div>
        <div class="row1-col21 fl">二加热段</div>
        <div class="row1-col31 fl">一加热段</div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">炉顶温度设定保护上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T11SP_H,
              '均热段炉顶温度设定保护上限',
              'T11SP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T11SP_H }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T21SP_H,
              '三加热段炉顶温度设定保护上限',
              'T21SP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T21SP_H }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T31SP_H,
              '二加热段炉顶温度设定保护上限',
              'T31SP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T31SP_H }}
        </div>
        <div
          class="row1-col3 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T41SP_H,
              '一加热段炉顶温度设定保护上限',
              'T41SP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T41SP_H }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">炉顶温度设定保护下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T11SP_L,
              '均热段炉顶温度设定保护下限',
              'T11SP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T11SP_L }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T21SP_L,
              '三加热段炉顶温度设定保护下限',
              'T21SP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T21SP_L }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T31SP_L,
              '二加热段炉顶温度设定保护下限',
              'T31SP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T31SP_L }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.T41SP_L,
              '一加热段炉顶温度设定保护下限',
              'T41SP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.T41SP_L }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">空烟温度设定保护上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.JRD_KPTH,
              '均热段空烟温度设定保护上限',
              'JRD_KPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.JRD_KPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.SJR_KPTH,
              '三加热段空烟温度设定保护上限',
              'SJR_KPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.SJR_KPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.EJR_KPTH,
              '二加热段空烟温度设定保护上限',
              'EJR_KPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.EJR_KPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.YJR_KPTH,
              '一加热段空烟温度设定保护上限',
              'YJR_KPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.YJR_KPTH }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">空烟温度设定保护下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.JRD_KPTL,
              '均热段空烟温度设定保护下限',
              'JRD_KPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.JRD_KPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.SJR_KPTL,
              '三加热段空烟温度设定保护下限',
              'SJR_KPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.SJR_KPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.EJR_KPTL,
              '二加热段空烟温度设定保护下限',
              'EJR_KPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.EJR_KPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.YJR_KPTL,
              '一加热段空烟温度设定保护下限',
              'YJR_KPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.YJR_KPTL }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">煤烟温度设定保护上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.JRD_MPTH,
              '均热段煤烟温度设定保护上限',
              'JRD_MPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.JRD_MPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.SJR_MPTH,
              '三加热段煤烟温度设定保护上限',
              'SJR_MPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.SJR_MPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.EJR_MPTH,
              '二加热段煤烟温度设定保护上限',
              'EJR_MPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.EJR_MPTH }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.YJR_MPTH,
              '一加热段煤烟温度设定保护上限',
              'YJR_MPTH',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.YJR_MPTH }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">煤烟温度设定保护下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.JRD_MPTL,
              '均热段煤烟温度设定保护下限',
              'JRD_MPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.JRD_MPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.SJR_MPTL,
              '三加热段煤烟温度设定保护下限',
              'SJR_MPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.SJR_MPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.EJR_MPTL,
              '二加热段煤烟温度设定保护下限',
              'EJR_MPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.EJR_MPTL }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP.YJR_MPTL,
              '一加热段煤烟温度设定保护下限',
              'YJR_MPTL',
              'G_TEMP'
            )
          "
        >
          {{ infoList.G_TEMP.YJR_MPTL }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">炉膛压力设定保护</div>
        <div class="row1-col2 fl">控制点上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PFYSP_H,
              '炉膛压力控制点上限',
              'PFYSP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PFYSP_H }}
        </div>
        <div class="row1-col2 fl">控制点下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PFYSP_L,
              '炉膛压力控制点下限',
              'PFYSP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PFYSP_L }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">空气总管压力设定保护</div>
        <div class="row1-col2 fl">控制点上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PKQZGSP_H,
              '空气总管压力控制点上限',
              'PKQZGSP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PKQZGSP_H }}
        </div>
        <div class="row1-col2 fl">控制点下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PKQZGSP_L,
              '空气总管压力控制点下限',
              'PKQZGSP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PKQZGSP_L }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">煤气总管压力设定保护</div>
        <div class="row1-col2 fl">控制点上限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PMQZGSP_H,
              '煤气总管压力控制点上限',
              'PMQZGSP_H',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PMQZGSP_H }}
        </div>
        <div class="row1-col2 fl">控制点下限</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PVCL.PMQZGSP_L,
              '煤气总管压力控制点下限',
              'PMQZGSP_L',
              'G_PVCL'
            )
          "
        >
          {{ infoList.G_PVCL.PMQZGSP_L }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">基本空燃比</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.JBFMB_B11,
              '均热段空燃比',
              'JBFMB_B11',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.JBFMB_B11 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.JBFMB_B21,
              '三加热段空燃比',
              'JBFMB_B21',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.JBFMB_B21 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.JBFMB_B31,
              '二加热段空燃比',
              'JBFMB_B31',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.JBFMB_B31 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.JBFMB_B41,
              '一加热段空燃比',
              'JBFMB_B41',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.JBFMB_B41 }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">停止加煤气空燃比</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.SJFMB1_B11,
              '均热段停止加煤空燃比',
              'SJFMB1_B11',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.SJFMB1_B11 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.SJFMB1_B21,
              '一加热段停止加煤空燃比',
              'SJFMB1_B21',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.SJFMB1_B21 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.SJFMB1_B31,
              '一加热段停止加煤空燃比',
              'SJFMB1_B31',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.SJFMB1_B31 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_BCS.SJFMB1_B41,
              '一加热段停止加煤空燃比',
              'SJFMB1_B41',
              'G_BCS'
            )
          "
        >
          {{ infoList.G_BCS.SJFMB1_B41 }}
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">停止加煤气负压</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_PFJ.MENLUFW1,
              '停止加煤气负压',
              'MENLUFW1',
              'G_PFJ'
            )
          "
        >
          {{ infoList.G_PFJ.MENLUFW1 }}
        </div>
        <div class="row1-col2 fl">钢坯总数量</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP__p__RLGWXT.GPNUM,
              '钢坯总数量',
              'GPNUM',
              'G_TEMP__p__RLGWXT'
            )
          "
        >
          {{ infoList.G_TEMP__p__RLGWXT.GPNUM }}
        </div>
        <div class="row1-col2 lcolor fl">
          <div
            class="otherbtn"
            :style="{ background: infoList.G_BCS.CSFMB ? 'red' : '#29DFAE' }"
            @click="toIpt(infoList.G_BCS.CSFMB, '初始空燃比', 'CSFMB', 'G_BCS')"
            style="color: #fff"
          >
            {{ infoList.G_BCS.CSFMB ? "跟踪DCS" : "固定当前" }}
          </div>
        </div>
      </div>
      <div class="row2">
        <div class="row1-col1 fl">各段钢坯数量</div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP__p__RLGWXT.GPNUM4,
              '均热段钢坯数量',
              'GPNUM4',
              'G_TEMP__p__RLGWXT'
            )
          "
        >
          {{ infoList.G_TEMP__p__RLGWXT.GPNUM4 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP__p__RLGWXT.GPNUM3,
              '三加热钢坯数量',
              'GPNUM3',
              'G_TEMP__p__RLGWXT'
            )
          "
        >
          {{ infoList.G_TEMP__p__RLGWXT.GPNUM3 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP__p__RLGWXT.GPNUM2,
              '二加热钢坯数量',
              'GPNUM2',
              'G_TEMP__p__RLGWXT'
            )
          "
        >
          {{ infoList.G_TEMP__p__RLGWXT.GPNUM2 }}
        </div>
        <div
          class="row1-col2 lcolor fl"
          @click="
            toIpt(
              infoList.G_TEMP__p__RLGWXT.GPNUM1,
              '一加热钢坯数量',
              'GPNUM1',
              'G_TEMP__p__RLGWXT'
            )
          "
        >
          {{ infoList.G_TEMP__p__RLGWXT.GPNUM1 }}
        </div>
      </div>
    </div>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <JRLParam04
      v-if="JRLParam04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam04>
    <JRLParamwt
      v-if="JRLParamwt"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParamwt>
  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import JRLParam04 from "@/components/JRL_Measurement.vue";
import JRLParamwt from "@/components/Jrwtdata.vue";
export default {
  name: "JRL_cysz",
  components: {
    Historical,
    inputVal,
    JRLParam04,
    JRLParamwt,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      JRLParamwt: false,
      JRLParam04: false,
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
  },
  computed: {},
  mounted() {},
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        console.log(str.charAt(str.length - 1));
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    closeCompon() {
      this.$emit("sendStatus", "JRL_cysz", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "JRL_Measurement":
          return (this.JRLParam04 = false);
        case "JRLParamwt":
          return (this.JRLParamwt = false);
      }
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true);
        case 4:
          return (this.JRLParam04 = true);
        case 5:
          return (this.JRLParamwt = true);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlcysz {
  width: 53vw;
  height: 78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  font-size: 1vw;
  .ziti {
    font-size: 0.9vw;
  }
  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    padding-top: 0.5vh;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }
  .titlelable {
    width: 53vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 5vh;
  }
  .button-top {
    width: 5vw;
    height: 3vh;
    background-color: #008db1;
    text-align: center;
    line-height: 3vh;
    margin-right: 3vw;
    cursor: pointer;
  }
  .button-top1 {
    width: 6vw;
    height: 3vh;
    background-color: #008db1;
    text-align: center;
    line-height: 3vh;
    margin-right: 3vw;
    cursor: pointer;
  }
  .main-top {
    width: 50vw;
    height: 59vh;
    border-left: #00e4ff20 1px solid;
    border-top: #00e4ff20 1px solid;
    border-right: #00e4ff20 1px solid;

    margin-left: 1.5vw;
    margin-top: 5vh;
  }
  .row1 {
    width: 50vw;
    height: 5vh;
    background-color: #003f5b;
    color: #2fc3e3;
  }
  .row1-col1 {
    width: 15vw;
    height: 4vh;
    color: #2fc3e3;
    text-align: center;
    line-height: 4vh;
    border-right: #00e4ff20 1px solid;
  }
  .row1-col11 {
    width: 15vw;

    color: #2fc3e3;
    text-align: center;
    line-height: 5vh;
    border-right: #00e4ff20 1px solid;
  }
  .row1-col2 {
    width: 8vw;
    height: 4vh;
    color: #2fc3e3;
    text-align: center;
    line-height: 4vh;
    border-right: #00e4ff20 1px solid;
  }
  .row1-col21 {
    width: 8vw;
    height: 5vh;
    color: #2fc3e3;
    text-align: center;
    line-height: 5vh;
    border-right: #00e4ff20 1px solid;
  }
  .row1-col3 {
    width: 9vw;
    color: #2fc3e3;
    text-align: center;
    line-height: 4vh;
    // border-right: #00e4ff20 1px solid;
  }
  .row1-col31 {
    width: 9vw;
    color: #2fc3e3;
    text-align: center;
    line-height: 5vh;
    // border-right: #00e4ff20 1px solid;
  }
  .row2 {
    width: 50vw;
    height: 4vh;
    border-bottom: #00e4ff20 1px solid;
    color: #8aeaff;
    line-height: 4vh;
  }
  .lcolor {
    color: #00ffb4;
    cursor: pointer;
  }
  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 50vw;
  }
}
</style>